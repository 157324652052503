import { Injectable } from '@angular/core';
import { AppEvent } from '../_types/app-event.types';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppEventService {
  protected eventSubject = new Subject<AppEvent<any>>();
  public events = this.eventSubject.asObservable();

  private showTicketDetailInfo = new Subject();
  public	showTicketDetailInfo$=this.showTicketDetailInfo.asObservable();
  private artistDetailsInfo=new Subject();
  public  artistDetailsInfo$=this.artistDetailsInfo.asObservable();
  private gigDetailsInfo=new Subject();
  public gigDetailsInfo$=this.gigDetailsInfo.asObservable();
  dispatchEvent(event: AppEvent<any>) {
    this.eventSubject.next(event);
  }
  setIsSharedUrl(value:boolean){
    this.showTicketDetailInfo.next({isShared:value})
  }
  setIsSharedArtistUrl(value:boolean){
    this.artistDetailsInfo.next({isShardArtist:value})
  }
  SetIsSharedGigUrl(value:boolean){
    this.gigDetailsInfo.next({isSharedGig:value});
  }
}
